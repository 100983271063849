import axios from "axios";
import Vue from "vue";

var profile_id = Vue.auth.user().userUniqueId;

export async function getForm(profile,form_name = "all") {
  console.log(profile_id)
  profile_id = profile ?? profile_id
  console.log(profile_id)
  return await axios
    .get("GetFormService", {
      params: {
        profile_id:profile_id,
        form_name,
        farm_profile_id: "a-test-farm",
        created_date: "ALL",
        form_type: "risk",
        compliance_type: "farming-for-the-future"
      }
    })
    .then(res => {
      return res.data[0].objectList;
    })
    .catch(error => {
      console.log(error)
      return [];
    });
}

export async function submitCert(payload) {
  return await axios
  .post("CertifyFormService", payload)
}

export async function approveForm(payload) {
  return await axios
  .post("SaveFormService", payload)
}

export async function getCertificate(farm_profile_id) {
  console.log(profile_id)
  return await axios
    .get("GetFormService", {
      params: {
        profile_id,
        form_name: "Supplier Information",
        farm_profile_id,
        created_date: "ALL",
        form_type: "risk",
        compliance_type: "farming-for-the-future"
      }
    })
    .then(res => {
      const data = res.data[0].objectList;
      if (data?.length > 0) {
        return data.reduce((a, b) =>
          Vue.moment(a.created_date, "DD-MM-YYYY h:mm").format() >
          Vue.moment(b.created_date, "DD-MM-YYYY h:mm").format()
            ? a
            : b
        );
      }
      return null;
    })
    .catch(error => {
      console.log(error);
      return [];
    });
}
